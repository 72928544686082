@import 'styles/mixins.scss';

.job-description-form {
  width: 100%;
  @include flexbox(column, flex-start, stretch, 24px);

  label {
    font-size: 1rem;
    display: inline-block;
    margin-bottom: 12px;
  }

  .resume-channel {
    display: flex;
    gap: 8px;

    @include responsive-until($sm) {
      flex-direction: column;
      gap: 24px;
    }

    .bp4-popover-wrapper .bp4-popover-target button.select-toggle-button {
      min-height: 40px;
    }

    .common-form-control {
      margin-bottom: 0;
    }
  }
}
