@import 'styles/vars.scss';

.edit-profile-pictue-modal {
  .edit-img-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .editable-img-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;

      .remove-image-btn {
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        color: $ColorError;

        &:hover {
          text-decoration: underline;
        }
      }

      .editable-img-placeholder {
        height: 122px;
        width: 122px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed $ColorLightBlue;
        border-radius: 50%;

        svg {
          color: $ColorDarkBlue;
        }
      }

      img {
        height: 122px;
        width: 122px;
        border-radius: 50%;
      }
    }

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      color: $ColorDarkBlue;
      border: 1px solid $ColorDarkBlue;
      background-color: $ColorWhite;
      font-size: 16px;
      border-radius: 22px;
      font-weight: 400;
      height: 44px;
      cursor: pointer;

      &:hover {
        color: $ColorBlue;
        border-color: $ColorBlue;
      }

      &:active,
      &:focus {
        border-color: $ColorBlue;
      }
    }
    input {
      display: none;
    }
  }
}
