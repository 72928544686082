@import 'styles/mixins.scss';

.post-job-user {
  width: 100%;
  max-width: 400px;

  .row {
    @include flexbox(row, flex-start, stretch, 0px 24px);
    @include mobile {
      flex-direction: column;
    }
  }

  .common-form-control {
    margin-bottom: 24px;

    &.phone-input {
      margin-bottom: 0;
    }
  }

  .reusable-checkbox {
    padding: 0;

    label {
      font-size: 14px;
      line-height: 20px;
    }

    input::after {
      border-radius: 4px;
    }
  }

  .re-captcha {
    margin-top: 40px;
    min-height: 60px;
    padding: 0 20px;
    background-color: $ColorGraySoft;
    border-radius: 12px;

    display: flex;
    align-items: center;
    position: relative;

    &.has-error {
      border: 1px solid #ff0000;
    }

    .circle-icon {
      width: 36px;
      height: 36px;
      border: 6px solid #0086f8;
      border-radius: 50%;
    }

    .bp4-icon,
    .circle-icon {
      opacity: 0;
      visibility: hidden;
      transition: all 250ms ease-in-out;
      color: #0086f8;

      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-16px, -50%);
    }

    .bp4-icon.active,
    .circle-icon.active {
      opacity: 1;
      visibility: visible;
    }
  }
}
