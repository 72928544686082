@import 'styles/mixins.scss';

$border-radius: 14px;

.autocomplete {
  //   width: 100%;
  //   max-width: 400px;
  //   min-height: 280px;

  .autocomplete-label {
    display: inline-block;
    font-size: 1rem;
    margin-bottom: 12px;
  }

  .autocomplete-input {
    input.bp4-input {
      height: 40px;
      padding-left: 16px;
      border: 1px solid transparent;
      border-radius: 12px;

      background: $ColorGraySoft;
      box-shadow: none;
      transition: all 150ms ease-in-out;

      font-size: 1rem;
      font-weight: 400;
      color: $ColorDarkGray;

      &:focus {
        border-color: $ColorPrimaryLight;
      }

      &::placeholder {
        color: $ColorGrayLight;
      }
    }

    .bp4-input-left-container,
    .bp4-input-action {
      @include flexbox(row, center, center);
      height: 100%;
      padding: 0 12px;
      top: 50%;
      transform: translateY(-50%);
    }

    .bp4-icon-chevron-down {
      transform: rotate(0);
      transition: transform 150ms ease-in-out;
    }

    &.bp4-popover2-open .bp4-icon-chevron-down {
      transform: rotate(180deg);
    }

    .autocomplete-input-container {
      position: relative;

      &.has-error input.bp4-input {
        border-color: #ff0000;
      }
    }

    .autocomplete-input-error {
      font-size: 12px;
      color: #ff0000;
      position: absolute;
      left: 0;
      top: 100%;
      transform: translateY(4px);
    }
  }

  .autocomplete-popover {
    border-radius: $border-radius;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

    .bp4-popover2-content {
      border-radius: $border-radius;
    }
  }

  .autocomplete-list {
    padding: 0;
    border-radius: $border-radius;
    max-height: 200px;
    overflow-y: auto;

    // &::-webkit-scrollbar {
    //   display: none;
    // }

    & > li:first-child > .autocomplete-item {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    & > li:last-child > .autocomplete-item {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  .autocomplete-item {
    padding: 10px 20px;
    align-items: center;
    font-size: 1rem;
    color: $ColorDarkGray;

    & > *:not(:last-child) {
      margin-right: 12px;
    }

    .bp4-menu-item-icon {
      height: auto;
    }

    .bp4-menu-item-label {
      color: $ColorGrayLight;
      width: 180px;
      @include text-overflow;
    }

    &.remove {
      color: $ColorError;
    }

    &.create {
      min-height: 50px;

      .bp4-menu-item-icon svg {
        width: 30px;
      }
    }
  }
}
