@import 'styles/vars.scss';
@import 'styles/mixins.scss';

header {
  &.main-header {
    height: 92px;
    width: 100%;
    position: relative;
    background-color: $ColorWhite;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    .main-header-inner {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      height: 100%;
      max-width: 1400px;
      width: 100%;

      .toggle-header-btn {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 20px;
        width: 25px;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;

        span {
          background-color: $ColorDarkBlue;
          height: 4px;
          width: 100%;
          border-radius: 22px;
          display: block;
          position: absolute;
          opacity: 1;
          left: 0;
          transition: 0.25s ease-in-out;

          &:nth-child(1) {
            top: 0px;
            transform-origin: left center;
          }

          &:nth-child(2) {
            top: 8px;
            transform-origin: left center;
            transform: translateX(0);
          }

          &:nth-child(3) {
            top: 16px;
            transform-origin: left center;
          }
        }

        &.active {
          span {
            &:nth-child(1) {
              transform: rotate(45deg);
              top: -2px;
              left: 8px;
            }

            &:nth-child(2) {
              width: 0%;
              opacity: 0;
              transform: translateX(-20px);
            }

            &:nth-child(3) {
              transform: rotate(-45deg);
              top: 16px;
              left: 8px;
            }
          }
        }
      }

      .navbar {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 100%;

        .header-logo {
          margin-right: 40px;

          @include tablet {
            & > a {
              display: flex;
            }
          }

          // img {
          //   height: 60px;
          //   width: 240px;

          //   @media (max-width: 991px) {
          //     height: 55px;
          //     width: 220px;
          //   }
          // }

          @media (max-width: 991px) {
            margin: 0 15px;
          }
        }
      }

      .header-actions {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-end;

        @media (min-width: 991px) {
          flex-grow: 1;
        }

        .searchbox-wrapper {
          max-width: 550px;
          flex-grow: 1;
          background-color: $ColorWhite;
          border-radius: 22px;
          height: 44px;
          margin: 0 15px;
        }

        .user-menu {
          .user-menu-trigger {
            .default-image {
              width: 40px;
              height: 40px;

              span {
                font-size: 14px;
              }
            }
          }

          .dropdown-menu-wrapper {
            .bp4-overlay {
              .bp4-overlay-content {
                .bp4-popover2 {
                  margin-top: 12px !important;

                  .bp4-popover2-content {
                    .bp4-menu {
                      min-width: 144px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .post-job-button {
      margin-right: 24px;
      background-color: $ColorPrimaryLight;
      padding: 12px 24px;
      border-radius: 60px;
      font-size: 1rem;
      color: #fff;

      @include mobile {
        display: none;
      }
    }
  }
}

.header-nav-list {
  &.nav-list-desktop {
    display: flex;
    height: 100%;
    flex-direction: row;
    list-style: none;

    .nav-item {
      margin-right: 32px;

      .nav-link {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        color: $ColorDarkBlue;
        font-weight: 400;
        font-size: 18px;
        transition: 0.2s ease-in-out;
        white-space: nowrap;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          height: 6px;
          transform: scaleX(0);
          transition: 0.2s ease-in-out;
        }

        &:hover,
        &:focus {
          color: $ColorLightBlue;
          outline: none;
        }

        &.active-nav-link {
          color: $ColorLightBlue;
          font-weight: 600;

          &::after {
            content: '';
            background-color: $ColorLightBlue;
            width: 100%;
            transform: scaleX(100%);
          }

          &:hover {
            color: $ColorDarkBlue;
          }
        }
      }
    }
  }

  &.nav-list-mobile {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $ColorDarkBlue;
    transition: 0.3s ease-in-out;
    opacity: 0;
    z-index: -1;
    transform: translateX(-100%);
    top: 92px;

    &.open-nav-list {
      opacity: 1;
      z-index: 5;
      transform: translateX(0);
    }

    .nav-item {
      height: 65px;
      border-top: 1px solid $ColorWhite;

      .nav-link {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 40px;
        font-size: 24px;
        font-weight: 400;
        color: $ColorWhite;
        transition: 0.2s ease-in-out;

        &:hover,
        &:focus {
          text-decoration: underline;
        }

        &.active-nav-link {
          color: $ColorLightBlue;
        }
      }
    }
  }
}

body {
  &.sidebar-open {
    @media (max-width: 991px) {
      overflow: hidden;
    }
  }
}
