@import 'styles/vars.scss';

.talent-page {
  padding: 20px 30px 20px 30px;
  min-width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 991px) {
    padding: 20px 15px 20px 15px;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1400px;
  }
}
