@import 'styles/mixins.scss';

.post-job {
  flex: 1;
  padding: 24px;
  @include flexbox(row, center, flex-start);

  @include tablet {
    padding: 16px;
  }

  @include mobile {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 100px;
  }

  &-wrapper {
    width: 100%;
    max-width: 900px;
    padding: 40px;
    border-radius: 24px;
    background-color: #fff;
    box-shadow: 0px 12px 24px -16px rgba(0, 0, 0, 0.08);

    &.large {
      max-width: 1200px;
    }

    @include mobile {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &-progress {
    padding-bottom: 40px;
  }

  &-content {
    @include flexbox(column, flex-start, center);
    @include responsive-from($xs) {
      padding-bottom: 40px;
    }
  }

  &-title {
    margin-top: 24px;
    margin-bottom: 40px;
    font-size: 2rem;
    font-weight: 500;
    color: $ColorDarkGray;
    text-align: center;

    @include responsive-until($sm) {
      font-size: 1.75rem;
    }

    @include mobile {
      font-size: 1.5rem;
    }
  }

  &-assistant {
    position: relative;
    display: flex;

    @include responsive-until($sm) {
      @include flexbox(column, flex-start, center, 24px);
      padding: 0 16px;
    }
  }

  &-description {
    position: absolute;
    top: -16px;
    left: calc(100% + 32px);

    width: 300px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #eeeff2;
    background: #fff;
    box-shadow: 0px 12px 32px -8px rgba(0, 0, 0, 0.1);

    font-size: 1rem;
    font-weight: 400;
    color: #6c6c6c;

    @include tablet {
      width: 280px;
    }

    @include responsive-until($sm) {
      position: static;
      width: auto;
      text-align: center;
    }

    @include mobile {
      font-size: 14px;
    }
  }

  &-divider {
    height: 1px;
    width: 100%;
    margin-bottom: 40px;
    background-color: $ColorGrayLight;
  }

  &-heading {
    font-size: 1.5rem;
    font-weight: 500;
    color: $ColorMidGrey;
    margin-bottom: 40px;
    @include flexbox(row, flex-start, center, 12px);

    @include mobile {
      font-size: 1.25rem;
    }
  }

  &-toggle {
    @include flexbox(row, space-between, center);
    margin-bottom: 24px;

    .post-job-heading {
      margin-bottom: 0;
    }
  }
}

.post-job-review,
.post-job-new {
  width: 100%;
  max-width: 1148px;
  margin: 24px auto;
  padding: 40px 80px;
  background: #fff;
  border-radius: 24px;

  @include mobile {
    padding: 40px 24px;
  }

  .post-job-title {
    margin-top: 0;
    margin-bottom: 24px;
  }

  .post-job-editable {
    margin-bottom: 40px;
  }

  .new-job-item {
    width: 100%;
    max-width: 500px;
    margin-bottom: 40px;
    @include flexbox(column, flex-start, stretch, 40px);

    @include mobile {
      max-width: none;
    }

    .row {
      @include flexbox(row, flex-start, stretch, 24px);

      & > * {
        width: calc(100% / 3);
      }

      @include mobile {
        flex-direction: column;
        gap: 40px;

        & > * {
          width: 100%;
        }
      }
    }

    &.resume-channel {
      label {
        display: block;
        margin-bottom: 16px;

        font-size: 16px;
        font-weight: 400;
        color: #404040;
      }

      .row > * {
        width: 50%;

        @include mobile {
          width: 100%;
        }
      }
    }
  }
}

.post-job-congrats {
  width: 100%;
  max-width: 900px;
  padding: 16px;
  margin: 0 auto;

  text-align: center;

  h1 {
    font-size: 500;
  }
}

.post-job-multiselect {
  width: 100%;

  label.BS-label {
    font-size: 1rem;
    color: $ColorDarkGray;
    margin-bottom: 12px;
  }

  .bp4-popover-wrapper .bp4-overlay .bp4-transition-container .bp4-multi-select-popover {
    padding: 12px 4px 12px 0;
    margin-top: 10px !important;

    .bp4-menu-item {
      font-size: 1rem;
      padding: 10px 20px;
    }

    .bp4-menu-item.bp4-active {
      background-color: transparent;
      color: $ColorDarkGray;

      &:hover {
        background-color: $ColorGraySuperSoft;
      }
    }
  }

  .bp4-popover-wrapper .bp4-popover-target .bp4-tag-input {
    min-height: 40px;
    padding: 8px 15px;
  }

  .bp4-tag-input-values {
    gap: 4px;

    .reusable-listed-tag {
      margin: 0;
    }
  }
}
