@import 'styles/vars.scss';

.talent-search-page {
  padding: 20px 30px 20px 30px;
  min-width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 991px) {
    padding: 20px 15px 20px 15px;
  }

  .talent-search-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1400px;

    .talent-search-page-inner {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;

      .talent-search-list-wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;

        .talent-search-list {
          list-style: none;
          transition: 0.2s ease;
          margin-bottom: 50px;

          @media (max-width: 991px) {
            background-color: $ColorWhite;
            border-radius: 15px;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
            padding: 0;
          }
        }
      }
    }
  }
}
