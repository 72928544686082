@import 'styles/vars.scss';

.user-management-filters-panel {
  width: 100%;
  background-color: $ColorWhite;
  padding: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid $ColorStroke;
  border-radius: 10px;
  margin-bottom: 30px;

  @media (max-width: 991px) {
    flex-direction: column;
  }

  .settings-navbar-toggler {
    position: relative !important;
    align-self: self-start;
  }

  .select-group {
    flex-grow: 1;
    display: flex;
    flex-direction: row;

    @media (max-width: 991px) {
      width: 100%;
      margin-bottom: 10px;
    }

    > div {
      margin-right: 10px;
      min-width: 220px;
      flex-grow: 1;

      @media (max-width: 991px) {
        min-width: 100px;
      }
    }
  }

  .search-group {
    flex-grow: 1;
    display: flex;

    @media (max-width: 991px) {
      width: 100%;
    }

    > button {
      margin-left: 10px;
      width: 100px;
    }
  }
}
