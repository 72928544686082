@import 'styles/vars.scss';

.talent-search-page {
  .subheader-pannel {
    background: $ColorWhite;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 20px 20px 0px 0px;
    padding: 18px 24px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    > div {
      display: flex;
      align-items: center;
    }

    .toggle-filters-panel-button {
      margin-right: 15px;
      border-radius: 50%;
      background-color: $ColorDarkBlue;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: $ColorWhite;
        transition: 0.25s ease;
      }

      &:hover {
        background-color: $ColorBlue;
      }

      &.open {
        svg {
          transform: rotateY(180deg);
        }
      }
    }

    .talents-count {
      color: $ColorDarkGray;
      font-weight: 400;
      font-size: 16px;
    }
  }
}
