@import 'styles/mixins.scss';

.input-field {
  margin-bottom: 0;

  & > label {
    font-size: 1rem;
    font-weight: 400;
    color: $ColorDarkGray;
    margin-bottom: 12px;
  }

  .reusable-input-container {
    min-height: 40px;
  }
}
