@import 'styles/mixins.scss';

.post-job-preferences {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  .toggle-button {
    max-width: 140px;
    margin-bottom: 24px;
  }

  & > label {
    margin-bottom: 8px;
    font-size: 1rem;
    font-weight: 400;
  }

  .bp4-editable-text {
    width: 400px;
    padding: 8px 16px;
    color: $ColorDarkGray;

    &::before,
    &.bp4-editable-text-editing::before {
      background-color: $ColorGraySoft;
      box-shadow: none;
      border-radius: 12px;
    }
  }
}
