@import 'styles/vars.scss';

.notes-tab {
  .notes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    .sort-menu {
      display: flex;
      align-items: center;

      .selected-sort-option {
        margin-left: 8px;
        color: $ColorGrayLight;
        font-weight: 400;
        font-size: 14px;
      }

      .dropdown-menu-wrapper {
        .sort-options-menu-trigger {
          color: $ColorDarkBlue;
          margin: 0;
          font-size: 14px;
          font-weight: 400;
          display: flex;
          align-items: center;

          svg {
            margin-right: 5px;
            color: inherit;
          }
        }
      }
    }
  }

  > ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 100%;

    > li {
      width: 100%;
      margin-bottom: 20px;
      border: 1px solid $ColorStroke;
      border-radius: 10px;
      padding: 42px 30px 36px 30px;
      background-color: $ColorGraySoft;
      display: flex;
      flex-direction: column;
      color: $ColorDarkGray;
      font-size: 16px;

      .note-item-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        .note-item-type {
          border-radius: 4px;
          background-color: $ColorGray;
          color: $ColorWhite;
          text-transform: capitalize;
          padding: 2px 6px;
          line-height: 100%;
          font-size: 16px;

          &.type-primary {
            background-color: $ColorLightBlue;
          }
        }

        .note-cations {
          a {
            + a {
              margin-left: 16px;
            }

            svg {
              color: $ColorDarkGray;
            }
          }
        }
      }

      .note-item-content {
        margin-bottom: 40px;

        p {
          margin-bottom: 5px;
        }
      }

      .note-item-date {
        display: flex;
        align-items: center;
        flex-direction: row;
        font-size: 14px;
        color: $ColorGray;

        .note-updated-user {
          margin-right: 8px;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
