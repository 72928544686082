@import 'styles/vars.scss';

.jobs-pipeline-filters-panel {
  position: sticky;
  top: 20px;
  min-width: 0;
  max-width: 0;
  margin-right: 0;
  background: $ColorWhite;
  border-radius: 20px;
  display: flex;
  align-self: flex-start;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  transition: 0.8s ease;
  transform: scaleX(0);
  animation-direction: reverse;
  transform-origin: top left;

  &.open {
    min-width: 250px;
    max-width: 250px;
    margin-right: 20px;
    transform: scaleX(100%);

    .jobs-pipeline-filters-panel-inner {
      transition: 1.8s opacity ease;
      opacity: 1;
    }
  }

  .jobs-pipeline-filters-panel-inner {
    opacity: 0;
    transition: 0.1s opacity ease;
  }
}

.jobs-pipeline-filters-panel-inner {
  width: 100%;
  height: 100%;
  border-radius: inherit;

  > div {
    width: 100%;
    padding: 24px;
  }

  .filters-search-section {
    background-color: $ColorWhite;
    border-radius: 20px 20px 0px 0px;

    .filters-reset-btn {
      font-weight: 400;
      font-size: 14px;
      color: $ColorDarkBlue;
      margin-bottom: 25px;
      display: block;

      &:hover {
        color: $ColorBlue;
        text-decoration: underline;
      }
    }

    hr {
      height: 1px !important;
      width: 100%;
      border: none;
      background-color: $ColorLighterGray;
    }

    .mini-searchbox {
      margin-top: 24px;
    }
  }

  .filters-section {
    background-color: $ColorGraySoft;
    border-radius: 0px 0px 20px 20px;

    .checkbox-tag {
      + .checkbox-tag {
        margin-top: 24px;
      }
    }

    hr {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
}
