@import 'styles/vars.scss';

.user-management-card {
  .no-results-card {
    width: 100%;
    background-color: $ColorWhite;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border: 1px solid $ColorStroke;
    border-radius: 10px;
    position: relative;
    min-height: 70px;

    .lds-dual-ring {
      top: 20%;
    }
  }

  .users-table-wrapper {
    table {
      width: 100%;
      border: 1px solid $ColorStroke;
      border-radius: 20px;
      overflow: hidden;
      border-spacing: 0;

      tr {
        height: 88px;
        background-color: $ColorWhite;

        &:first-of-type {
          background-color: $ColorLighterGray;
          height: 44px;

          th {
            color: $ColorDarkGray;
            font-weight: 600;
            font-size: 14px;
            padding: 13px 5px 10px 5px;
            text-align: left;
          }
        }

        &:last-of-type {
          td {
            border-bottom: none;
          }
        }
      }

      td {
        border-bottom: 1px solid $ColorStroke;
        padding: 13px 20px 10px 5px;
        color: $ColorDarkGray;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        &.user-name {
          font-weight: 700;
        }

        &.user-management-menu {
          min-width: 75px;
          text-align: right;

          .user-management-menu-trigger {
            display: block;

            svg {
              min-width: 18px;
              color: $ColorDarkGray;
            }
          }
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        .default-pic-frame {
          font-size: 16px;
          width: 40px !important;
          height: 40px !important;
          display: flex;
          justify-content: center;

          span {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    ul {
      &.users-list-mobile {
        list-style: none;
        border-radius: 10px;
        background-color: $ColorWhite;
        border: 1px solid $ColorStroke;

        li {
          &.user-management-list-item {
            margin: 0;
            border-bottom: $ColorLighterGray 1px solid;
            padding: 20px 30px 14px 30px;
            position: relative;
            display: flex;
            flex-direction: row;

            &:last-child {
              border-bottom: none;
            }

            .menu-wrapper {
              position: absolute;
              right: 10px;
              top: 20px;
              min-width: 85px;
              text-align: right;

              .user-management-menu-trigger {
                display: block;

                svg {
                  min-width: 18px;
                  color: $ColorDarkGray;
                  transform: rotate(90deg);
                }
              }
            }

            .user-image {
              img {
                width: 56px;
                height: 56px;
                border-radius: 50%;
              }

              .default-pic-frame {
                font-size: 16px;
                width: 56px !important;
                height: 56px !important;
                display: flex;
                justify-content: center;

                span {
                  display: flex;
                  align-items: center;
                }
              }
            }
            .user-item-inner {
              flex-grow: 1;
              padding-left: 15px;
              display: flex;
              flex-direction: column;

              h3 {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;

                color: $ColorDarkGray;
                margin-bottom: 5px;
              }
              p {
                margin-bottom: 3px;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
              }

              ul {
                &.user-details {
                  list-style: none;
                  display: flex;
                  flex-direction: row;

                  li {
                    margin-right: 8px;

                    &.user-last-avtivity {
                      color: $ColorGrayLight;
                      font-weight: 400;
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
