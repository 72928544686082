@import 'styles/vars.scss';

.talent-card-header {
  display: flex;
  width: 100%;
  background-color: $ColorWhite;
  padding: 43px 45px 13px 45px;
  border-radius: 20px 20px 0 0;
  border: 1px solid $ColorStroke;
  border-bottom: none;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &-picture {
    width: 108px;
    height: 108px;
    display: flex;
    align-items: flex-start;

    @media (max-width: 991px) {
      margin-bottom: 10px;
    }
  }

  &-data {
    flex-grow: 1;
    padding-left: 40px;
    color: $ColorDarkGray;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (max-width: 991px) {
      padding-left: 0;
      text-align: center;
    }

    h1 {
      font-weight: 500;
      font-size: 28px;
      line-height: 120%;
      margin-top: 0;
      margin-bottom: 5px;
    }

    &-job-location {
      list-style: none;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 5px;

      @media (max-width: 991px) {
        flex-direction: column;
      }

      li {
        font-size: 20px;
        line-height: 22px;
        font-weight: 400;

        + li {
          @media (min-width: 991px) {
            margin-left: 26px;
            position: relative;

            &::before {
              content: '';
              width: 4px;
              height: 4px;
              background: $ColorDarkGray;
              border-radius: 50%;
              position: absolute;
              top: 8px;
              left: -15px;
            }
          }
        }
      }
    }

    &-preferences {
      list-style: none;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 5px;

      @media (max-width: 991px) {
        justify-content: center;
      }

      li {
        font-size: 18px;
        line-height: 20px;
        font-weight: 400;

        + li {
          margin-left: 26px;
          position: relative;

          &::before {
            content: '';
            width: 4px;
            height: 4px;
            background: $ColorDarkGray;
            border-radius: 50%;
            position: absolute;
            top: 8px;
            left: -15px;
          }
        }
      }
    }

    .talent-actions {
      display: flex;
      align-items: center;
      padding-right: 40px;
      margin-bottom: 15px;
      margin-top: 20px;

      @media (max-width: 991px) {
        padding-right: 0;
        justify-content: center;
      }

      .select-wrapper {
        margin-bottom: 0;
        max-width: 150px;
      }

      > button {
        margin-right: 10px;
        width: 120px;
        height: 42px;
      }
    }
  }

  &-menu {
  }
}
