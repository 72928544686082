@import 'styles/vars.scss';

.talent-panel-portal {
  .talent-panel {
    background-color: $ColorGraySoft;
    padding: 20px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      margin-right: 8px;
      border-radius: 0;

      &-track {
        background: #d4d4d4;
        border-radius: 0;
      }

      &-thumb {
        background: #404040;
      }
    }

    @media (max-width: 991px) {
      padding: 0;

      .talent-card {
        margin: 0 10px;
      }
    }
  }
}
