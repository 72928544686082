@import 'styles/vars.scss';

.cv-tab {
  .cv-wrapper {
    #sidebar {
      position: relative;
      display: none;
    }
    #page-container {
      position: relative;
      max-height: 800px;

      &::-webkit-scrollbar {
        width: 5px;
        margin-right: 8px;
        border-radius: 0;

        &-track {
          background: $ColorLighterGray;
          border-radius: 0;
        }

        &-thumb {
          background: $ColorDarkGray;
        }
      }
    }
  }
}
