@import 'normalize.css';
@import '@blueprintjs/core/lib/css/blueprint.css';
@import '@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';

@import './vars.scss';
@import './fonts.scss';
@import './mixins.scss';

* {
  padding: 0;
  margin: 0;
}

body {
  min-height: 100vh;
  background-color: #f8f8f8;

  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  color: $ColorDarkGray;
  font-family: $primaryFont !important;

  ol,
  ul {
    list-style: none;
  }

  #root {
    min-height: 100vh;
    height: 100%;

    .main-frame {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      height: 100%;

      main {
        flex-grow: 1;
      }
    }
  }
}

a:hover {
  text-decoration: none !important;
}

.Toastify__toast {
  font-family: $primaryFont;
}
