@import 'styles/vars.scss';

.talent-nav-panel {
  background-color: $ColorWhite;
  border: 1px solid $ColorStroke;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    background-color: $ColorDarkBlue;
    border-radius: 0;
  }

  &-inner {
    display: flex;
    justify-content: center;
    align-items: center;

    &-back {
      color: $ColorDarkGray;
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 40px;

      @media (max-width: 991px) {
        color: $ColorWhite;
      }

      &:hover,
      &:focus {
        color: $ColorLightBlue;
      }

      svg {
        margin-right: 7px;
        width: 8px;
        height: 9px;

        @media (max-width: 450px) {
          width: 12px;
          height: 15px;
        }
      }
    }

    &-position {
      color: $ColorDarkGray;
      font-weight: 500;
      font-size: 24px;

      @media (max-width: 991px) {
        color: $ColorWhite;
      }
    }
  }

  &-nav {
    display: flex;
    align-items: center;

    @media (max-width: 450px) {
      display: none;
    }

    span {
      color: $ColorDarkGray;
      font-weight: 500;
      font-size: 24px;
      margin: 0 14px;

      @media (max-width: 991px) {
        color: $ColorWhite;
      }
    }

    a {
      svg {
        color: $ColorDarkGray;
        width: 8px;
        height: 9px;

        @media (max-width: 991px) {
          color: $ColorWhite;
        }
      }

      &:hover,
      &:focus {
        svg {
          color: $ColorLightBlue;

          @media (max-width: 991px) {
            color: $ColorWhite;
          }
        }
      }
    }
  }
}
