@import 'styles/vars.scss';

span {
  &.status {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-weight: 700;

    svg {
      margin-right: 8px;
    }

    &.status-active {
      color: $ColorLightBlue;
    }

    &.status-pending {
      color: $ColorWarning;
    }

    &.status-deleted {
      color: $ColorError;
    }

    &.status-revoke {
      color: $ColorGray;
    }
  }
}
