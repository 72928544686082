@import 'styles/vars.scss';

.validatePasswordPanel {
  padding: 20px 5px 30px 5px;

  ul {
    li {
      font-size: 14px;
      color: $ColorDarkGray;
      transition: 0.2s ease-in-out;
      margin-bottom: 5px;
      list-style-type: disc;
      list-style-position: inside;

      &.valid {
        text-decoration: line-through;
        color: $ColorGray;
      }
    }
  }
}
