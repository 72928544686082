@import 'styles/mixins.scss';

.post-job-navigation {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  border-top: 1px solid $ColorGrayLight;

  &.is-review {
    padding-top: 40px;
  }

  @include mobile {
    padding: 24px 16px 16px;
    background-color: #fff;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }

  button.bp4-button {
    font-size: 18px;
    font-weight: 500;
    border-radius: 60px;
    min-height: 50px;
    min-width: 110px;
    transition: all 100ms ease-in-out;

    @include mobile {
      min-height: 40px;
      min-width: 100px;
      font-size: 1rem;
    }
  }

  button.bp4-button.outlined {
    background-color: transparent;
    color: $ColorMidGrey;
    border: 1px solid $ColorMidGrey;

    &:hover {
      color: $ColorDarkBlue;
      border-color: $ColorDarkBlue;
    }

    &:disabled {
      color: #c7c7c7;
      border: 1px solid #c7c7c7;
    }
  }

  button.bp4-button.contained {
    color: #fff;
    background-color: $ColorPrimaryLight;

    &:hover {
      background-color: #002a84;
    }

    &:disabled {
      color: #fff;
      background-color: #c7c7c7;
    }
  }

  .review-button-group {
    display: flex;
    align-items: center;
    gap: 40px;
  }

  button.bp4-button.post-job {
    min-width: 150px;
  }

  button.bp4-button.save-as-draft {
    min-width: 0;
    padding: 10px 0px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $ColorMidGrey;
  }

  @include responsive-until($sm) {
    &.is-review {
      position: static;
      padding: 0;
      padding-top: 40px;

      button.bp4-button.back {
        display: none;
      }
    }

    .review-button-group {
      width: 100%;
      flex-direction: column-reverse;
      gap: 24px;
    }

    button.bp4-button.post-job {
      width: 100%;
    }
  }
}
