@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.settings-page {
  padding: 0 30px 20px 30px;
  min-width: 100%;
  display: block;
  justify-content: center;

  @media (max-width: 991px) {
    padding: 0 15px 20px 15px;
  }

  .settings-page-inner {
    display: flex;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    max-width: 1400px;

    @media (max-width: 991px) {
      display: block;
    }

    .settings-card {
      flex-grow: 1;
      padding-top: 30px;
      width: calc(100% - 230px);
      position: relative;

      @media (max-width: 991px) {
        width: 100%;
      }

      .settings-navbar-toggler {
        position: absolute;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease-in-out;
        color: $ColorBlack;
        width: 25px;
        height: 25px;

        svg {
          color: $ColorBlack;
          height: 17px;
        }
      }

      .settings-card-header {
        display: flex;
        width: 100%;
        background-color: $ColorWhite;
        padding: 64px 30px 44px;
        border-radius: 20px 20px 0 0;
        border: 1px solid $ColorStroke;
        border-bottom: none;
        justify-content: center;
        align-items: center;

        .profile-picture {
          width: 90px;
          height: 90px;
          position: relative;
        }
      }

      .settings-tabs {
        .bp4-tab-panel {
          margin-top: 20px;

          .settings-card-tab {
            width: 100%;
            background-color: $ColorWhite;
            border: 1px solid $ColorStroke;
            padding: 0.75rem 2rem 1.5rem;
            transition: 0.1s ease;
            display: flex;
            justify-content: center;
            flex-direction: column;
            border-radius: 20px;

            .settings-card-tab-inner {
              padding: 70px 0;
              display: flex;
              justify-content: center;
              flex-direction: column;

              .row {
                width: 100%;
                margin-bottom: 35px;

                &.form-actions {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 10px;
                }
              }
            }
          }
        }

        .bp4-tab-indicator {
          height: 6px;
          background-color: $ColorLightBlue;
        }

        .bp4-tab-list {
          border-top: none;

          & > *:not(:last-child) {
            margin-right: 40px;

            @include mobile {
              margin-right: 25px;
            }
          }
        }
      }
    }
  }
}
