@import 'styles/vars.scss';

.talent-item-card {
  display: flex;
  padding: 16px 28px;
  border: 1px solid $ColorStroke;
  border-radius: 10px;
  margin-bottom: 20px;
  transition: 0.25 ease-in-out;
  flex-direction: row;
  height: auto;
  background-color: $ColorWhite;
  position: relative;

  &-mobile {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    background-color: transparent;
    padding: 10px 10px 20px;
    position: relative;

    + {
      .talent-item-card-mobile {
        border-top: 1px solid $ColorLighterGray;
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
      text-align: center;
      align-items: center;
    }

    .talent-img {
      width: 82px;
      height: 82px;
    }

    .talent-item-inner {
      flex-grow: 1;
      padding: 0 40px 0 25px;
      display: flex;
      flex-direction: column;

      @media (max-width: 600px) {
        padding: 0;
      }

      h3 {
        font-size: 28px;
        margin-bottom: 5px;
        margin-top: 0;
        font-weight: 500;

        a {
          color: $ColorDarkGray;
        }
      }

      p {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 2px;

        &.position-title {
          color: $ColorDarkGray;
        }

        &.company-name {
          color: $ColorDarkBlue;
        }

        &.talent-location {
          color: $ColorDarkGray;
        }
      }

      .talent-actions {
        margin-top: 15px;
        display: flex;
        align-items: center;
        flex-direction: row;

        > button {
          margin-right: 5px;
          width: 120px;
        }

        .select-wrapper {
          max-width: 130px;
          margin-bottom: 0;
        }
      }
    }

    .talent-item-menu-container {
      position: absolute;
      right: 5px;
      text-align: right;
      top: 10px;
      z-index: 5;
      width: 150px;

      .talent-item-menu-trigger {
        width: 35px;

        svg {
          width: 18px;
          color: $ColorBlack;
          transform: rotate(90deg);
        }
      }
    }
  }

  .talent-item-menu-container {
    .talent-item-menu-trigger {
      position: absolute;
      right: 10px;
      text-align: right;
      top: 15px;
      width: 60px;

      svg {
        width: 18px;
        color: $ColorBlack;
      }
    }
  }

  .talent-item-checkbox {
    width: 30px;
    padding-top: 40px;
  }

  .talent-item-inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .talent-item-card-header {
      display: flex;
      width: 100%;
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      .talent-header-data-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;

        .talent-img {
          width: 140px;

          .default-image {
            width: 92px;
            height: 92px;
          }
        }

        .talent-personal-data {
          display: flex;
          flex-direction: column;
          padding-right: 20px;

          h3 {
            color: $ColorDarkGray;
            font-weight: 500;
            font-size: 28px;
            margin-bottom: 10px;

            a {
              color: $ColorDarkGray;

              &:hover {
                color: $ColorBlue;
              }
            }
          }

          ul {
            list-style: none;
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-bottom: 5px;

            li {
              color: $ColorDarkGray;
              font-weight: 400;
              font-size: 18px;
              position: relative;
              line-height: 20px;

              + li {
                margin-left: 26px;
                position: relative;

                &::before {
                  content: '';
                  width: 4px;
                  height: 4px;
                  background: $ColorDarkGray;
                  border-radius: 50%;
                  position: absolute;
                  top: 8px;
                  left: -15px;
                }
              }
            }
          }

          p {
            color: $ColorGrayLight;
            font-weight: 400;
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 5px;
          }
        }
      }

      .talent-actions {
        display: flex;
        justify-self: flex-end;
        align-items: center;
        padding-right: 40px;
        margin-bottom: 15px;

        .select-wrapper {
          margin-bottom: 0;

          .bp4-popover-wrapper {
            .bp4-overlay {
              .bp4-popover.bp4-select-popover {
                .bp4-popover-content {
                  .bp4-menu {
                    max-height: 350px;
                  }
                }
              }
            }
          }
        }

        > button {
          margin-right: 10px;
          width: 120px;
          height: 42px;
        }
      }
    }

    .talent-body {
      ul {
        list-style: none;

        > li {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          margin-bottom: 20px;

          .list-item-title {
            width: 100px;
            min-width: 100px;
            color: $ColorDarkGray;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            width: 140px;
          }

          .list-item-data {
            color: $ColorDarkGray;
            font-size: 18px;
            line-height: 20px;
            font-weight: 400;

            p {
              margin-bottom: 2px;
            }

            .list-item-data-date {
              color: $ColorLighterGray;
              font-size: 16px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
