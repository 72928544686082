@import 'styles/mixins.scss';

.post-job-company {
  width: 100%;
  max-width: 400px;
  min-height: 280px;
}

.new-company {
  width: 100%;
  max-width: 400px;

  .reusable-textarea {
    font-size: 1rem;
  }

  #newCompanyDetails {
    @include flexbox(column, flex-start, stretch, 24px);
  }

  #newCompanyImage {
    @include flexbox(row, center, center, 24px);

    & > img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: cover;
    }

    & > button {
      min-width: 44px;
      min-height: 44px;
      border-radius: 50%;
      border: 1px solid $ColorError;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .new-company-upload {
    @include flexbox(column, flex-start, center, 30px);

    & > span {
      @include flexbox(row, center, center);
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 1px dashed #007dff;
    }

    & > label {
      padding: 10px 20px;
      border: 1px solid $ColorDarkBlue;
      border-radius: 60px;

      font-size: 1rem;
      font-weight: 500;
      color: $ColorDarkBlue;
      cursor: pointer;
    }
  }
}
