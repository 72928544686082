@import 'styles/vars.scss';

.talent-card {
  &-header {
  }

  .reusable-tabs-list {
    .bp4-tab-list {
      .bp4-tab {
        height: 45px;
      }
    }
  }

  &-tab {
    width: 100%;
    background-color: $ColorWhite;
    border: 1px solid $ColorStroke;
    padding: 0.75rem 2rem 1.5rem;
    transition: 0.1s ease;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    margin-bottom: 20px;

    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      &-title {
        color: $ColorLightBlue;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
      }

      &-edit-btn {
        background-color: $ColorGraySoft;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        padding: 10px;
        color: $ColorDarkGray;
        align-self: flex-end;
        transition: 0.2s ease-in-out;
        margin-left: 15px;

        &:hover,
        &:focus {
          background-color: darken($color: $ColorGraySoft, $amount: 5);
        }

        svg {
          color: inherit;
        }
      }
    }

    form {
      .row {
        width: 100%;
        margin-bottom: 20px;
      }

      .quill {
        width: 100%;

        &.edit-mode {
        }

        &.readonly-mode {
          .ql-toolbar {
            display: none;
          }

          .ql-container {
            border: none;

            .ql-editor {
              padding: 0;
            }
          }
        }
      }

      .form-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        transition: 0.3s ease;

        &[aria-hidden='true'] {
          opacity: 0;
          transform: translateY(-30px);
        }
      }
    }
  }
}
