@import 'styles/mixins.scss';

.post-job-advance {
  width: 100%;
  @include flexbox(column, flex-start, stretch, 24px);

  .salary {
    @include flexbox(row, flex-start, stretch, 24px);

    @include responsive-until($sm) {
      flex-direction: column;
    }

    & > * {
      flex: 1;
    }
  }
}
