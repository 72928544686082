//colors:
$ColorDarkBlue: #00074f;
$ColorBlue: #002a84;
$ColorLightBlue: #2f80ed;
$ColorBrightTurquoise: #00c8df;
$ColorWhite: #fff;
$ColorBlack: #000;
$ColorError: #ff0000;
$ColorErrorSoft: #f16147;
$ColorWarning: #fdb718;
$ColorGreen: #3edf83;
$ColorGray: #8a8a8a;
$ColorLighterGray: #d4d4d4;
$ColorGrayLight: #c7c7c7;
$ColorDarkGray: #404040;
$ColorGraySoft: #f8f8f8;
$ColorGraySuperSoft: #f6f6f6;
$ColorStroke: #eeeff2;
$ColorDefault: #e2e2e2;

$ColorMidGrey: #6c6c6c;
$ColorPrimaryLight: #307ff6;

//fonts:
$primaryFont: ploni, sans-serif;

// breakpoints
$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;
