@import './vars.scss';

@mixin flexbox(
  $direction: row,
  $justify-content: flex-start,
  $align-items: stretch,
  $gap: 0,
  $wrap: nowrap
) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-direction: $direction;
  flex-wrap: $wrap;
  gap: $gap;
}

@mixin responsive-until($width) {
  @media screen and (max-width: #{$width - 1px}) {
    @content;
  }
}

@mixin responsive-from($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

// < 992px
@mixin tablet {
  @include responsive-until($md) {
    @content;
  }
}

// < 576px
@mixin mobile {
  @include responsive-until($xs) {
    @content;
  }
}

@mixin text-overflow($max-width: none) {
  max-width: $max-width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
