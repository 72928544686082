@import 'styles/mixins.scss';

.post-job-editable {
  position: relative;
  @include flexbox(column, flex-start, flex-start, 16px);

  .editable-label {
    padding-right: 60px;
    font-size: 1rem;
    font-weight: 400;
  }

  .editable-value {
    font-size: 1rem;
    font-weight: 400;
    color: $ColorMidGrey;
    margin-bottom: 0;

    &.multiline {
      padding-right: 60px;
    }

    .bp4-editable-text-input {
      width: 100% !important;
    }
  }

  &.edit-mode .editable-value {
    width: 400px;
    padding: 8px 16px;
    color: $ColorDarkGray;

    &::before,
    &.bp4-editable-text-editing::before {
      background-color: $ColorGraySoft;
      box-shadow: none;
      border-radius: 12px;
    }
  }

  &.edit-mode::before {
    content: '';
    position: absolute;
    inset: -16px;
    border-radius: 12px;
    border: 1px solid $ColorPrimaryLight;
  }

  button.editable-edit {
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px;
    border-radius: 10px;
    background-color: $ColorGraySoft;
  }

  .editable-action {
    @include flexbox(row, flex-start, center, 24px);
    position: relative;
    align-self: flex-end;

    & > button {
      font-size: 1rem;
      font-weight: 500;
    }

    .editable-cancel {
      color: $ColorMidGrey;

      &:hover {
        background-color: transparent;
      }
    }

    .editable-save {
      padding: 10px 24px;
      border-radius: 60px;
      background-color: $ColorBlue;
      color: #fff;

      &:hover {
        color: #fff;
        background-color: $ColorPrimaryLight;
      }
    }
  }

  .editable-row {
    width: 100%;
    @include flexbox(row, flex-start, flex-start, 40px);

    @include mobile {
      flex-direction: column;
      gap: 24px;
    }

    & > *,
    & > .autocomplete,
    & > .editable-value:not(.resume-channel-value) {
      width: 25%;

      @include mobile {
        width: 100%;
      }
    }

    &.editable-salary > * {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .editable-team {
    width: 100%;
    @include flexbox(column, flex-start, stretch, 32px);

    @include mobile {
      gap: 40px;
    }

    .editable-row {
      align-items: center;
    }

    .editable-team-field {
      font-size: 1rem;
      font-weight: 400;
      color: $ColorMidGrey;
      @include flexbox(row, flex-start, center, 16px);
    }

    button.editable-team-add {
      position: relative;
      align-self: flex-end;
      width: auto;
      padding: 10px 24px;
      border-radius: 60px;
      background-color: $ColorBlue;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;

      &:hover {
        color: #fff;
        background-color: $ColorPrimaryLight;
      }

      @include mobile {
        width: 100%;
      }
    }
  }

  .editable-preferences {
    width: 100%;
    display: flex;
    flex-direction: column;

    .toggle-button {
      max-width: 140px;
      margin-bottom: 24px;
    }

    .editable-label {
      margin-bottom: 8px;
    }
  }

  .autocomplete {
    width: 100%;
    max-width: 400px;
  }

  .reusable-textarea-wrapper {
    margin-bottom: 10px;

    .reusable-textarea-container {
      padding: 0;

      &:focus-within {
        border-color: transparent;
      }
    }

    .reusable-textarea-container .reusable-textarea {
      padding: 12px 16px;
      font-size: 1rem;
    }
  }

  .post-job-multiselect {
    margin-bottom: 10px;
  }

  .tags-collection {
    width: 100%;
    padding-right: 60px;

    .tags-collection-list {
      gap: 8px;
    }

    .tags-collection-list .reusable-listed-tag {
      margin: 0;
      padding: 6px 14px !important;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  @include mobile {
    &.edit-mode {
      padding: 16px;
    }

    &.edit-mode::before {
      inset: 0;
    }

    &.edit-mode .editable-value {
      width: 100%;
    }

    .autocomplete {
      max-width: 100%;
    }
  }
}
