@import 'styles/mixins.scss';

.post-job-team {
  @include flexbox(column, flex-start, stretch, 32px);
  margin-bottom: 40px;

  @include mobile {
    gap: 40px;
  }

  .team-row {
    width: 100%;
    @include flexbox(row, flex-start, center, 40px);

    & > * {
      width: 25%;
    }

    @include mobile {
      flex-direction: column;
      gap: 24px;

      & > * {
        width: 100%;
      }
    }
  }

  .team-field {
    font-size: 1rem;
    font-weight: 400;
    color: $ColorMidGrey;
    @include flexbox(row, flex-start, center, 16px);
  }

  button.team-add {
    position: relative;
    align-self: flex-end;
    width: auto;
    padding: 10px 24px;
    border-radius: 60px;
    background-color: $ColorBlue;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;

    &:hover {
      color: #fff;
      background-color: $ColorPrimaryLight;
    }

    @include mobile {
      width: 100%;
    }
  }
}
