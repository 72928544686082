@import 'styles/mixins.scss';

.phone-input-field {
  .reusable-input-container {
    min-height: 40px;
  }

  .select-wrapper .bp4-popover-wrapper .bp4-popover-target button.select-toggle-button {
    min-height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  & > label {
    font-size: 1rem;
    color: $ColorDarkGray;
    margin-bottom: 12px;
  }
}
