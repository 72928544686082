@import 'styles/mixins.scss';

.bp4-dialog.post-job-verification {
  background-color: #fff;
  border: 1px solid #eeeff2;
  box-shadow: 0px 12px 24px -16px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  text-align: center;

  .bp4-dialog-body {
    padding: 24px;

    @include mobile {
      padding: 16px;
    }
  }

  p {
    margin: 0;
  }

  .verification-title {
    font-size: 1.5rem;
    font-weight: 500;
    color: $ColorDarkGray;
    margin-bottom: 24px;
  }

  .verification-description {
    font-size: 1rem;
    font-weight: 400;
    color: $ColorGray;

    &.multi-line {
      @include flexbox(column, flex-start, center, 16px);
    }

    &.grey-color {
      color: $ColorMidGrey;
    }
  }

  .verification-link {
    color: $ColorPrimaryLight;
  }

  .verification-input {
    margin-top: 56px;
    margin-bottom: 40px;

    @include mobile {
      margin-top: 40px;
    }
  }

  .verification-button-group {
    @include flexbox(row, center, center, 24px);
    margin-bottom: 56px;

    @include mobile {
      margin-bottom: 40px;
    }
  }

  .verification-button {
    border-radius: 60px;
    padding: 16px 32px;
    border: none;
    font-size: 1rem;
    line-height: 1;
    font-weight: 500;

    @include mobile {
      padding: 14px 20px;
    }

    &.center {
      margin: 0 auto;
    }

    &.resend {
      padding: 0;
      background-color: transparent;
      color: $ColorMidGrey;
      font-weight: 500;
      min-height: 0;

      &:hover {
        background-color: transparent;
        color: $ColorDarkBlue;
      }
    }
  }

  &.existing-user .verification-button {
    min-width: 400px;
    margin-top: 32px;
    margin-bottom: 32px;

    @include mobile {
      min-width: 100%;
    }
  }

  &.congratulations .verification-button {
    margin-top: 40px;
  }

  .verification-footer {
    @include flexbox(column, flex-start, center, 32px);
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid #e2e2e2;

    font-size: 14px;
    color: $ColorGray;
    text-align: center;
  }

  .verification-socials {
    @include flexbox(row, center, center, 32px);
  }

  .verification-logo {
    margin-bottom: 40px;
  }

  &.resend-code,
  &.existing-user {
    width: 750px;
    text-align: left;

    .verification-title {
      font-weight: 600;
    }

    .verification-description {
      align-items: flex-start;
    }
  }
}
