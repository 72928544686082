@import 'styles/vars.scss';

.jobs-pipeline-page {
  padding: 20px 30px 20px 30px;
  min-width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 991px) {
    padding: 20px 15px 20px 15px;
  }

  .jobs-pipeline-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1400px;

    .jobs-pipeline-inner {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;

      .jobs-pipeline-list-container {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .jobs-pipeline-list {
          margin-bottom: 80px;
        }
      }
    }
  }
}
