@import 'styles/vars.scss';

.settings-navbar {
  width: 230px;
  height: 100%;
  position: relative;

  .toggle-settings-navbar-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    font-size: 16px;
    color: $ColorDarkGray;
    transition: 0.2s ease-in-out;
    font-weight: 400;
    padding: 4px;

    svg {
      margin-right: 6px;
      height: 10px;
    }

    &:hover {
      color: $ColorWhite;
      background-color: $ColorGrayLight;
      font-weight: 400;
    }
  }

  &.mobile-settings-navbar {
    transition: 0.3s ease-in-out;
    opacity: 0;
    z-index: -1;
    transform: translateX(-100%);
    top: 92px;
    width: 100%;
    height: 100%;
    background-color: $ColorGraySoft;
    position: absolute;

    ul {
      position: absolute;
    }

    &.open-settings-navbar {
      opacity: 1;
      z-index: 4;
      transform: translateX(-15px);
    }
  }

  ul {
    list-style: none;
    position: fixed;
    padding: 30px 10px;

    li {
      margin-bottom: 25px;

      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $ColorGray;
        font-weight: 400;
        font-size: 18px;
        transition: 0.2s ease;

        &.active-nav-link {
          color: $ColorLightBlue;
          font-weight: 600;
        }

        &:hover {
          color: $ColorDarkGray;
        }

        svg {
          margin-right: 15px;
        }
      }
    }
  }
}
