@import 'styles/vars.scss';

.talent-card-tab {
  &.profile-tab {
    p {
      font-size: 16px;
      line-height: 21px;
      color: $ColorDarkGray;
      margin-bottom: 14px;
      display: flex;
      align-items: center;

      &.about-text {
        white-space: pre-wrap;
      }
    }
  }

  .row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 18px;

    > span {
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
    }

    > svg {
      margin-right: 10px;
    }
  }

  &.experience-card {
    ul {
      list-style: none;

      li {
        display: flex;
        flex-direction: column;

        + li {
          margin-top: 20px;
        }

        .experience-inner {
          display: flex;
          flex-direction: row;
          margin-bottom: 8px;
          justify-content: flex-start;

          .experience-image {
            width: 53px;
            height: 53px;
          }
          .experience-data {
            padding-left: 16px;
            display: flex;
            flex-direction: column;

            h4 {
              color: $ColorDarkGray;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              margin-bottom: 5px;
            }

            p {
              color: $ColorDarkGray;
              font-size: 18px;
              line-height: 20px;
            }
            span {
              color: $ColorGray;
              font-weight: 400;
              font-size: 16px;
            }
          }
        }
        .experience-description {
          font-weight: 400;
          font-size: 18px;
          line-height: 120%;
          color: $ColorDarkGray;
        }
      }
    }
  }
}
