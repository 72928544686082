@import 'styles/vars.scss';

.job-pipeline-item {
  background-color: $ColorWhite;
  border-radius: 10px;
  border: 1px solid $ColorStroke;
  padding: 34px 22px;
  margin-bottom: 10px;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  .checkbox-wrapper {
    width: 60px;
    display: flex;
    padding-top: 4px;
  }

  .job-pipeline-item-inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .job-data {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;

      .job-data-inner {
        flex-grow: 1;

        h3 {
          margin-top: 0;
          margin-bottom: 10px;

          a {
            color: $ColorDarkGray;
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;

            &:hover {
              color: $ColorBlue;
            }
          }
        }

        > ul {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          > li {
            list-style: none;
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            display: flex;
            align-items: center;
            color: $ColorDarkGray;

            + li {
              margin-left: 26px;
              position: relative;

              &::before {
                content: '';
                width: 4px;
                height: 4px;
                background: $ColorDarkGray;
                border-radius: 50%;
                position: absolute;
                top: 8px;
                left: -15px;
              }
            }

            &.date {
              color: $ColorGrayLight;
            }

            &.status {
              svg {
                margin-right: 8px;
              }

              &.status-open {
                svg {
                  color: $ColorGreen;
                }
              }

              &.status-freeze {
                svg {
                  color: $ColorWarning;
                }
              }

              &.status-close {
                svg {
                  color: $ColorErrorSoft;
                }
              }
            }
          }
        }
      }
      .job-data-actions {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        > .reusable-button {
          &:first-child {
            margin-right: 10px;
          }
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 0;

        .job-data-inner {
          margin-bottom: 15px;

          > ul {
            flex-wrap: wrap;

            > li {
              margin-right: 20px;

              + li {
                margin-left: 0;

                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .job-pipeline {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;

      .pipeline-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        padding: 10px 0;
        background-color: $ColorWhite;
        border-radius: 10px;
        border: 1px solid $ColorStroke;

        + .pipeline-section {
          margin-left: 10px;
        }

        > li {
          list-style: none;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 76px;
          width: 100px;
          text-align: center;
          align-items: center;

          padding: 5px 2px;

          + li {
            border-left: $ColorStroke 1px solid;
          }

          > p {
            margin: 0;

            &.pipeline-number {
              color: $ColorDarkBlue;
              font-weight: 400;
              font-size: 24px;
            }

            &.pipeline-type {
              color: $ColorDarkGray;
              font-weight: 400;
              font-size: 14px;
            }
          }
        }
      }

      @media (max-width: 1340px) {
        flex-direction: column;
        align-items: flex-start;

        .pipeline-section {
          > li {
            width: 80px;
            height: 60px;
          }

          + .pipeline-section {
            margin-top: 15px;
            margin-left: 0;
          }
        }
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}
